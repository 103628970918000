import { IPaginatedListTotalCountType } from '../../types';
import { isSelfComputeListLength } from '../../utils/selfCompute';
import useGQLQuery from '../useGQLQuery';
import useCallbackWithCtx from 'src/hooks/useCallbackUtils/useCallbackWithCtx';
export default function useGQLPaginatedQuery(query, field, options = {}) {
    const [queryState, queryActions] = useGQLQuery(query, {
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-and-network',
        ...options,
        lazy: true,
    });
    const state = {
        ...queryState,
        data: queryState.data
            ? {
                ...queryState.data[field],
                hasMore: (() => {
                    const data = queryState.data[field];
                    if (data) {
                        const isSelfCompute = isSelfComputeListLength(data);
                        if (isSelfCompute) {
                            return (data.totalCount !== IPaginatedListTotalCountType.HAS_ENDED);
                        }
                        else {
                            return data ? data.list.length < data.totalCount : true;
                        }
                    }
                    return true;
                })(),
            }
            : queryState.data,
    };
    const actions = {
        refresh: useCallbackWithCtx((ctx, variables) => {
            options.listAnalytics?.custom('refresh');
            return ctx.queryActions.fetch({
                ...variables,
                pagination: {
                    offset: 0,
                    ...variables.pagination,
                },
            });
        }, { queryActions }),
        fetchMore: useCallbackWithCtx((ctx, variables) => {
            if (ctx.state.data &&
                ctx.state.data.hasMore &&
                !ctx.state.fetchingMore) {
                options.listAnalytics?.custom('next_page');
                return ctx.queryActions.fetchMore({
                    ...variables,
                    pagination: {
                        offset: ctx.state.data.list.length,
                        ...variables.pagination,
                    },
                });
            }
        }, { queryActions, state }),
    };
    return [state, actions];
}
